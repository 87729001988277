export default defineNuxtRouteMiddleware((to) => {
  const { $ROUTE, $auth, $unleash } = useNuxtApp()

  if (
    [
      $ROUTE.PROFILE_LEGAL,
      $ROUTE.PROFILE_TAX_CREATE,
      $ROUTE.PROFILE_TAX_UPDATE,
      $ROUTE.TAX_ID,
    ].includes(to.path) &&
    (!$auth.profile.permissions?.ViewTaxId ||
      !$unleash.features['profile-legal'])
  ) {
    return navigateTo($ROUTE.INDEX)
  }
})
